import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-staff-member',
  templateUrl: './staff-member.component.html',
  styleUrls: ['./staff-member.component.scss']
})
export class StaffMemberComponent implements OnInit {
  @Input() selectedStaff: any
  @Input() page: string

  constructor() { }

  ngOnInit(): void {
  }
}